import React from 'react';

const ThreePanel = () => {
    return (
        <div className="row">
            <div className="column"></div>
            <div className="column"></div>
            <div className="column"></div>
        </div>
    );
};

export default ThreePanel;