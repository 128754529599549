import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Weather from './pages/Weather';
import Contact from './pages/Contact';
import Game from './pages/Game';

const App = () => {
    const App = () => (
        <div>
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route exact path='/contact' component={Contact}/>
                <Route exact path='/weather' component={Weather}/>
                <Route exact path='/game' component={Game}/>
            </Switch>
        </div>
    )
    return (
        <Switch>
            <App/>
        </Switch>
    );
};

export default App;