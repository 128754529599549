import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown';

const Nav = (props) => {
    const [scrollStyle, setScrollStyle] = useState({
                backgroundColor: 'transparent',
                width: '100%'
            });
    const [txtColor, setTxtColor] = useState({ color: 'white' });
    const { isHome } = props;

    useEffect(() => {
        if (isHome) {
            window.onscroll = () => doScroll(setScrollStyle, setTxtColor);
        } else {
            document.getElementById('dropdownBtn').style = 'color: black; padding: 0';
            setScrollStyle({ backgroundColor: 'white', width: '100%' });
            setTxtColor({ color: 'black' });
        }
    }, [isHome]);

    return (
        <div id="nav-container" className="nav-box" style={scrollStyle} onScroll={() => doScroll()}>
            <ul>
                <li>
                    <Dropdown />
                </li>
                <li><Link style={txtColor} to="/contact">Contact</Link></li>
                <li><Link style={txtColor} to="/weather">Weather</Link></li>
                <li><Link style={txtColor} to="/game">Game</Link></li>
            </ul>
        </div>
    );
};

const doScroll = (setScrollStyle, setTxtColor) => {
    if(window.pageYOffset === 0) {
        document.getElementById('dropdownBtn').style = 'color: white; padding: 0';
        setScrollStyle({ backgroundColor: 'transparent', width: '100%' });
        setTxtColor({ color: 'white' });
    } else {
        document.getElementById('dropdownBtn').style = 'color: black; padding: 0';
        setScrollStyle({ backgroundColor: 'white', width: '100%' });
        setTxtColor({ color: 'black' });
    }
};

export default Nav;