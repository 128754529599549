import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles({
    root: {
        '& ul': {
            display: 'flex',
            flexDirection: 'column'
        }
    }
});

export default function Dropdown(textColor) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const offClickClose = () => {
        setAnchorEl(null);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles();

    return (
        <div>
            <Button id="dropdownBtn" style={{color:'white', padding: '0px'}} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                Home
            </Button>
            <Menu
                id="fade-menu"
                className={classes.root}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={offClickClose}
                TransitionComponent={Fade}
            >
                <MenuItem><a href="/">Home</a></MenuItem>
                <MenuItem onClick={() => handleClose()}><a href="/#section-1">Thing</a></MenuItem>
                <MenuItem onClick={() => handleClose()}><a href="/#section-2">About me</a></MenuItem>
                <MenuItem onClick={() => handleClose()}><a href="/#section-3">Specialties</a></MenuItem>
            </Menu>
        </div>
    );
}