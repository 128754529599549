import React, { Component } from 'react';

class Animate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weather: 'lightningstorm',
            bgColor: '#333',
            bgImg: ''
        };

        this.canvasRef = React.createRef();
        this.restartBind = this.restart.bind(this);
    }

    componentDidMount() {
        //Un-comment whenever, for the purpose of saving api calls
        /*fetch('https://api.ipify.org?format=json')
        .then(res => res.json())
        .then((json) => {
            fetch('https://ipapi.co/' +  json.ip + '/json/')
            .then(res => res.json())
            .then((json) => {
                fetch('https://cors-anywhere.herokuapp.com/https://api.darksky.net/forecast/3e0dcfc669bdcdbfecdd4cc1817178e8/' + json.latitude + ',' + json.longitude)
                .then(res => res.json())
                .then((json) => {
                    console.log(json)
                    this.setState({ weather: json.currently.icon })
                });
            });
        });*/

        this.init();
    }

    restart() {
        cancelAnimationFrame(this.rAF);
        this.init();
    }

    init() {
        this.canvas = this.canvasRef.current;
        console.log(this.canvas.width);
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.canvas.width = this.windowWidth-15;
        this.canvas.height = this.windowHeight;
        this.ctx = this.canvas.getContext("2d");
        this.ctx.font = "20px Arial";
        this.ctx.fillStyle = 'white';
        this.letters = [];
        this.weather = this.state.weather;
        this.clouds = [];
        this.sunTheta = 0.0;

        window.addEventListener('resize', this.restartBind, false);

        this.sleep(Math.floor(1000/60));
        this.rAF = requestAnimationFrame(this.updateGrid.bind(this));
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    randomFloatFromInterval(min, max) {
        return Math.random() * (max - min + 1) + min;
      }

    getLocInsideCloud() {
        var particleLoc = Math.floor((Math.random() * this.windowWidth));
        var cloudLoc;
            for (var i = 0; i < this.clouds.length; i++) {
                if (particleLoc > this.clouds[i]["location"] + 30 && particleLoc < this.clouds[i]["location"] + 230) {
                    cloudLoc = {
                        loc: particleLoc,
                        down: this.clouds[i]["y"] + 20
                    };

                    return cloudLoc
                }
            }

            cloudLoc = {
                loc: this.windowWidth,
                down: 0
            };

            return cloudLoc;
    }

    generateParticleInsideCloud(char) {
        var speed = Math.floor((Math.random() * 5) + 5);
        var cloudLoc = this.getLocInsideCloud();
        var character = {
            letter: char, //chars[Math.floor((Math.random() * (charLen-1)))],
            location: cloudLoc["loc"],
            speed: speed,
            down: cloudLoc["down"]
        };

        return character;
    }

    generateParticle(char) {
        var loc = Math.floor((Math.random() * this.windowWidth));
        var speed = Math.floor((Math.random() * 5) + 1);
        var character = {
            letter: char, //chars[Math.floor((Math.random() * (charLen-1)))],
            location: loc,
            speed: speed,
            down: 0
        };

        return character;
    }

    generateCloud() {
        var yStart = this.randomFloatFromInterval(0, 200);
        var speed = this.randomFloatFromInterval(1, 3);
        var cloud = {
            location: -240,
            speed: speed,
            y: 20 + yStart,
            opacity: this.randomFloatFromInterval(1, 2)/2
        };

        return cloud;
    }

    updateGrid() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        if (this.props.animation === 'weather') {
            if (this.weather === 'clear-day') {
                this.setState({ weather: this.weather, bgColor: '#00b5ff', bgImg: '' });

                this.drawSun();
            } else if (this.weather === 'clear-night') {
                this.setState({ weather: this.weather, bgColor: '111111', bgImg: '' });

                this.drawMoon();
                this.drawStars();
                this.drawShootingStar();
            } else if (this.weather === 'partly-cloudy-day') {
                this.setState({ weather: this.weather, bgColor: '#00b5ff', bgImg: '' });

                this.drawClouds();
            } else if (this.weather === 'partly-cloudy-night') {
                this.setState({ weather: this.weather, bgColor: '#111111', bgImg: '' });

                this.drawClouds();
                this.drawMoon();
            } else if (this.weather === 'cloudy') {
                this.setState({ weather: this.weather, bgColor: '#333333', bgImg: '' });

                this.drawClouds();
            } else if (this.weather === 'rain' || this.weather === 'sleet') {
                this.setState({ weather: this.weather, bgColor: '#333333', bgImg: '' });

                this.drawClouds();
                this.drawRain();
            } else if (this.weather === 'lightningstorm') {
                this.setState({ weather: this.weather, bgColor: '#333333', bgImg: '' });

                this.drawClouds();
                this.drawLightning();
                this.drawRain();
            } else if (this.weather === 'snow') {
                this.setState({ weather: this.weather, bgColor: '#666666', bgImg: '' });

                this.drawClouds();
                this.drawSnow();
            } else {
                this.setState({ weather: this.weather, bgColor: '#00b5ff', bgImg: '' });

                this.drawBirds();
            }
        } else if (this.props.animation === 'home') {
            this.setState({ weather: this.weather, bgColor: 'transparent', bgImg: 'linear-gradient(to top, #0000bb -350%, #001133 50%)' });
            this.drawHome();
        }

        this.sleep(Math.floor(1000/60));
        this.rAF = requestAnimationFrame(this.updateGrid.bind(this));
    }

    drawMoon() {

    }

    drawStars() {

    }

    drawShootingStar() {

    }

    drawLightning() {

    }

    drawSnow() {

    }

    drawBirds() {

    }

    rotateImageAndDraw(img, theta, x, y) {
        this.ctx.save();
        this.ctx.translate(x, y);
        this.ctx.rotate(theta);
        this.ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);
        this.ctx.restore();
    }

    drawSun() {
        var sunRay = document.getElementById("sunRay");
        var sunFace = document.getElementById("sunFace");


        var pos = {x: 50, y: 50};
        this.sunTheta += .01;
        this.rotateImageAndDraw(sunFace, -.3, pos.x, pos.y);
        this.rotateImageAndDraw(sunRay, this.sunTheta, pos.x, pos.y);
    }

    drawClouds() {
        const cloudImg = document.getElementById("cloud1");
        var i, loc, speed, y;
        if (this.clouds.length < 10) {
            this.clouds[this.clouds.length] = this.generateCloud();
            this.sleep(Math.floor(this.randomFloatFromInterval(10, 200)));
        }

        // Loop through list of letters and increment their position
        for (i = 0; i < this.clouds.length; i++) {
            loc = this.clouds[i]["location"];
            speed = this.clouds[i]["speed"];
            y = this.clouds[i]["y"];
            var opacity = this.clouds[i]["opacity"];
            loc += speed;

            this.ctx.globalAlpha = opacity;
            this.ctx.drawImage(cloudImg, loc, y);
            this.clouds[i]["location"] = loc;

            if (loc > this.windowWidth  + 100) {
                this.clouds[i] = this.generateCloud();
            }
        }
    }

    drawRain() {
        var i, loc, speed, down;

        if (this.letters.length < 50) {
            this.letters[this.letters.length] = this.generateParticleInsideCloud('\\');
        }

        this.ctx.globalAlpha = 1.0;

        // Loop through list of this.letters and increment their position
        for (i = 0; i < this.letters.length; i++) {
            loc = this.letters[i]["location"];
            speed = this.letters[i]["speed"];
            down = this.letters[i]["down"];
            down += speed;
            down *= 1.01;

            this.ctx.font = "25px Arial"
            this.ctx.fillText(this.letters[i]["letter"], loc, down);
            this.letters[i]["down"] = down;
            this.letters[i]["location"] = loc + speed;

            if (down > this.windowHeight) {
                this.letters[i] = this.generateParticleInsideCloud('\\');
            }
        }
    }

    drawHome() {
        var i, loc, speed, down;

        if (this.letters.length < 50) {
            this.letters[this.letters.length] = this.generateParticle('.');
        }

        this.ctx.globalAlpha = 1.0;

        // Loop through list of this.letters and increment their position
        for (i = 0; i < this.letters.length; i++) {
            loc = this.letters[i]["location"];
            speed = this.letters[i]["speed"];
            down = this.letters[i]["down"];
            down += speed;

            if (speed > 2.5) {
                this.ctx.font = "15px Arial"
            } else {
                this.ctx.font = "35px Arial"
            }
            this.ctx.fillText(this.letters[i]["letter"], loc, down);
            this.letters[i]["down"] = down;

            if (down > this.windowHeight) {
                this.letters[i] = this.generateParticle('.');
            }
        }
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.rAF);
        window.removeEventListener('resize', this.restartBind, false);
    }



    render() {
        return (
            <div>
                <canvas style={{position: 'absolute', left: 0, top: 0, backgroundImage: this.state.bgImg, backgroundColor: this.state.bgColor, zIndex: -1}} ref={this.canvasRef} />
            </div>
        );
    }
}
export default Animate;