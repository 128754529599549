import React, { useEffect, useState } from 'react';
import Nav from './Components/Nav';

const Contact = () => {
    const [color, setColor] = useState({backgroundColor: 'blue'});
    useEffect(() => {
        window.globalFunc = myFunc;
    })

    const myFunc = () => {
        setColor({backgroundColor: 'green'});
    }

    return (
        <div className="App center border" style={color}>
            <Nav isHome={false}/>
            <h1>Contact page</h1>
        </div>
    );
};

export default Contact;