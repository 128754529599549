import React from 'react';
import Map from './raycast-master/Map';
import Player from './raycast-master/Player';
import Scene from './raycast-master/components/scene';
import useCastRays from './raycast-master/hooks/useCastRays';
import { fromDegrees } from './raycast-master/Radians';

const fov = fromDegrees(60);
const map = new Map(64);
const player = new Player(160, 160, fromDegrees(0));
const resolution = 320;

const RayCast = () => {
  const rays = useCastRays(player, map, fov, resolution);

  return (
    <div style={styles.container}>
      <Scene
          height={400}
          mapHeight={map.height}
          player={player}
          map={map}
          rays={rays}
          resolution={resolution}
          width={740}
        />
    </div>
  );
}

export default RayCast;

const styles = {
  container: {
    display: 'flex',
  },
};