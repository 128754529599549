import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import Nav from './Components/Nav';
import Grid2x2 from './Components/Grid2x2';
import ThreePanel from './Components/ThreePanel';
import Animate from './Animation/Animate';

const WideSection = glamorous.section({
    height: window.innerHeight,
    borderRadius: '25px',
    borderColor: 'black',
    borderWidth: 'thin',
    marginTop: '10px'
});

const Home = () => {
    const animationStyle = 'home';
    const [pageStyle, setPageStyle] = useState({height: 0, color: 'white'});

    useEffect(() => {
        updateWindowDimensions(setPageStyle);
        window.addEventListener('resize', updateWindowDimensions(setPageStyle));

        return () => {
            window.removeEventListener('resize', updateWindowDimensions(setPageStyle));
        };
    }, []);

    return (
        <div className="App">
            <Nav isHome={true} />
            <div className="center">
                <div style={pageStyle}>
                    <h1 id="header" style={{marginTop: '150px'}}>Stanley P Antonuk</h1>
                    <h2>Engineer and future Astronaut</h2>
                    <p>I am a developer and an engineer at heart</p>
                    <p>With my passion for science and learning, I have a goal to prove to to the world</p>
                    <p>that anythingcan be learned through passion and determination</p><br/><br/>

                    <p>Stay on the lookout, for updates and keep your eyes peeled and an open mind, there could be hidden secrets</p>
                    <Animate animation={animationStyle} />
                </div>
            </div>
            <WideSection id="section-1">
                <Grid2x2 />
            </WideSection>
            <WideSection id="section-2">
                <ThreePanel />
            </WideSection>
            <WideSection id="section-3">
                <p>Weah</p>
            </WideSection>
        </div>
    );
};

const updateWindowDimensions = (setPageStyle) => {
    setPageStyle({height: window.innerHeight/1.55, marginBottom: '10px', color: 'white'});
};

export default Home;