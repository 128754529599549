//import Color from 'color';
import React from 'react';
import wall1 from '../textures/wall1.png';
import wall2 from '../textures/wall2.png';
import wall3 from '../textures/wall3.png';

export default function Column({
  color,
  distance,
  textureValue,
  mapHeight,
  number,
  resolution,
  textureOffset,
  screenHeight,
  screenWidth,
}) {
  const height = Math.min(mapHeight / distance * 255, screenHeight);
  const width = screenWidth / resolution;
  const top = (screenHeight - height) / 2;
  const styles = {
    //backgroundColor: adjustedColor,
    backgroundImage: "url(" + getTexture(textureValue) + ")",
    backgroundPosition: textureOffset + 'px 0',
    backgroundSize: (64 + width) + 'px 100%',
    backgroundRepeat: 'no-repeat',
    height,
    left: number * width,
    width,
    overflow: 'hidden',
    position: 'absolute',
    top,
  };

  return <div style={styles} />;
}

function getTexture(value) {
  if (value === 1) {
    return wall1;
  } else if (value === 2) {
    return wall2;
  } else if (value === 3) {
    return wall3;
  } else {
    return wall1;
  }
}
