import React, { Component } from 'react';
import Nav from './Components/Nav';
import Animate from './Animation/Animate';

class Weather extends Component {
    constructor(){
        super();
        this.state = { name: '', color: '' };
    }

    componentDidMount() {
        fetch('/hello/Peter/green')
        .then(res => res.json())
        .then((json) => {
            console.log(json)
            this.setState({ name: json.name, color: json.color })
        });
    }

    render() {
        const animationType = 'weather';
        return (
            <div className="App center">
                <Nav />
                <h1>Blog page</h1>
                <div>
                    <h2 style={{color: this.state.color}}>Hello {this.state.name}</h2>
                </div>
                <Animate animation={animationType} />
            </div>
        );
    }
}
export default Weather;