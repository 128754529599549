import React from 'react';

const Grid2x2 = () => {
    return (
        <div class="grid2x2">
            <div style={styles.border1}><div>one</div></div>
            <div style={styles.border2}><div>two</div></div>
            <div style={styles.border3}><div>three</div></div>
            <div style={styles.border4}><div>four</div></div>
        </div>
    );
};

export default Grid2x2;

const styles = {
  border1: {
    backgroundColor: 'red',
    margin: '10px',
  },
  border2: {
    backgroundColor: 'blue',
    margin: '10px',
  },
  border3: {
    backgroundColor: 'green',
    margin: '10px',
  },
  border4: {
    backgroundColor: 'yellow',
    margin: '10px',
  },
};