import React from 'react';
import RayCast from './Components/RayCast';
import Nav from './Components/Nav';
const Game = (props) => {
    const styles = {
        left: '50%',
        position: 'fixed',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    };

    return (
        <div>
            <Nav />
            <div style={styles}>
                <RayCast />
            </div>
        </div>
    );
};

export default Game;